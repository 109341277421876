import React from 'react';
import { Button } from 'modules/shared/components/atoms';

const MpoweringBusinesses = () => {
  return (
    <div>
      <div className="flex flex-col lg:gap-20 gap-10 place-content-center items-center text-center lg:mx-8 empowering-section z-10 relative ">
        <p className="lg:text-4xl text-3xl lg:leading-12 tracking-wider mx-auto lg:w-4/6 font-bold text-white">
          Empowering Businesses Through Innovative Software Solutions.
        </p>
        <Button icon={'/assets/icons/arrow-narrow-right-white.svg'}>Let’s Work Togother</Button>
        <div className="absolute left-0 top-0 -z-10">
          <img src="/assets/icons/ecllipse.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default MpoweringBusinesses;
