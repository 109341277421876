import React from 'react';
import './style.css';
import { LocationCard } from '../../molecules';
function WhereAreWE() {
  return (
    <div className="WhereAreWE md:my-8 md:relative lg:rounded-2xl">
      <div className="hidden lg:block">
        <img
          src="/assets/icons/location.png"
          alt=""
          className="absolute top-1/3 left-1/2 -translate-x-32 translate-y-1"
        />
        <img
          src="/assets/icons/location.png"
          alt=""
          className="absolute right-1/3 top-1/3 -translate-x-9 translate-y-6"
        />
        <img
          src="/assets/icons/location.png"
          alt=""
          className="absolute top-1/2 right-1/2 translate-x-28 -translate-y-16"
        />
      </div>
      <div className="md:absolute  right-20 top-20 mx-10 pt-16 md:p-0">
        <LocationCard location="Qatar Branch" address="Qatar , Doha" phone="+974  777  28  745 " />
        <LocationCard location="Saudi Arabia Branch" address="Saudi Arabia , Jeddah " phone="+966  51  156  3965" />
        <LocationCard location="Egypt Branch" address="Egypt , Cairo" phone="+20  107  070  3355" />
      </div>
    </div>
  );
}

export default WhereAreWE;
