import React from 'react';
import clsx from 'clsx';

const Typography = ({ children, variant = 'section', htmlTag: HtmlTag = 'p', className = '' }) => {
  const defaultClasses = {
    section: 'lg:text-5xl text-3xl pl-1 font-bold lg:tracking-wide leading-12',
    description: 'text-lg leading-7',
    title: 'text-2xl leading-10',
    overview: 'text-lg leading-6',
  };
  const combinedClass = clsx(defaultClasses[variant], className);
  return <HtmlTag className={combinedClass}>{children}</HtmlTag>;
};

export default Typography;
