import { Button } from 'modules/shared/components/atoms';
import { SubscribeCard } from 'modules/shared/components/organisms';
import React from 'react';

const FreeSubscribe = () => {
  return (
    <div>
      <div className="container">
        <SubscribeCard title="Start A Free Consultation With An Expert Now!">
          <div>
            <Button icon="/assets/icons/arrow-narrow-right.svg" variant="secondary" classNames="hover:bg-transparent">
              Get Service Now
            </Button>
          </div>
        </SubscribeCard>
      </div>
    </div>
  );
};

export default FreeSubscribe;
