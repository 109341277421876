import React from 'react';
import { Button, Typography } from 'modules/shared/components/atoms';

const WebAppDevelopmentCard = () => {
  return (
    <div className="flex flex-col lg:gap-5 w-full md:w-1/2 m-auto">
      <Typography htmlTag="h2" variant="section" className="text-center">
        App Modernization
      </Typography>
      <Typography htmlTag="p" variant="description" className="text-center font-medium lg:w-3/4 mx-auto">
        Evaluate performance, cloud utilization, and usability -modernize legacy code or transition to advanced
        technologies.
        <br className="hidden lg:block" /> Experience the Future: Transform Your Legacy Systems with Our App
        Modernization Solutions. Upgrade your software infrastructure seamlessly with our expert team. Harness the power
        of cutting-edge technologies to enhance efficiency, scalability, and user experience.
      </Typography>
      <div className="flex justify-center text-center mt-4">
        <Button classNames="bg-primary text-white text-base">Book free consultation</Button>
      </div>
    </div>
  );
};

export default WebAppDevelopmentCard;
