import React from 'react';
import { Button, Typography } from 'modules/shared/components/atoms';

const WhyAIMLServices = () => {
  return (
    <div>
      <div className="text-center mb-16">
        <Typography htmlTag="span" variant="section" className="">
          why choose
        </Typography>
        <Typography htmlTag="span" variant="section" className="text-primary">
          AI & ML
        </Typography>
        <Typography htmlTag="span" variant="section" className="">
          Service?
        </Typography>
      </div>
      <article className="grid gap-6 grid-cols-1 lg:grid-cols-5  my-8">
        <div className="self-center col-span-2 ">
          <img className="m-auto object-contain rounded-lg" src="/assets/images/Shaking_hands.png" alt="" />
        </div>
        <section className="col-span-3 lg:pr-24">
          <div className="lg:mr-24 ">
            <div>
              <Typography htmlTag="p" variant="title" className="font-semibold ">
                Accessible and Affordable
              </Typography>
              <Typography htmlTag="span" variant="title" className="text-secondary font-medium leading-8">
                AI and ML are accessible and affordable, though skilled developers are essential for effective
                management.
              </Typography>
            </div>
            <div>
              <article className="">
                <Typography htmlTag="p" variant="title" className="font-semibold">
                  Business Evolution
                </Typography>
                <Typography htmlTag="span" variant="title" className="text-secondary font-medium leading-8">
                  ML, as a part of AI, is a dynamic technology drivingbusiness evolution. Real-world applications of AI
                  & ML frameworks enablebusinesses to thrive.
                </Typography>
              </article>
              <article>
                <article className="my-4">
                  <Typography htmlTag="p" variant="title" className="font-semibold">
                    Industry Transformation
                  </Typography>
                  <Typography htmlTag="span" variant="title" className="text-secondary font-medium leading-8">
                    Our AI & ML services have transformed industries,supporting enterprises with strategic management
                    and simplifying projectimplementation.
                  </Typography>
                </article>
              </article>
              <div className="mt-8">
                <Button icon={'/assets/icons/arrow-narrow-right-white.svg'}>Get Service Now</Button>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  );
};

export default WhyAIMLServices;
