import { Button, TecnologyCard, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const icons = [
  {
    id: 1,
    title: 'Vertical SaaS Segment',
    url: '/assets/icons/saas-development-icon1.png',
  },
  {
    id: 2,
    title: 'Education',
    url: '/assets/icons/saas-development-icon2.png',
  },
  {
    id: 3,
    title: 'Marketing Software',
    url: '/assets/icons/saas-development-icon3.png',
  },
  {
    id: 4,
    title: 'ERP Platforms',
    url: '/assets/icons/saas-development-icon4.png',
  },
  {
    id: 5,
    title: 'CRM Products',
    url: '/assets/icons/saas-development-icon5.png',
  },
  { id: 6, title: 'Tourism', url: '/assets/icons/saas-development-icon6.png' },
  {
    id: 7,
    title: 'Collaboration Tools',
    url: '/assets/icons/saas-development-icon7.png',
  },
];

const BestSolutions = () => {
  return (
    <div className="grid gap-10 justify-center">
      <Typography variant="section" className="text-center text-xl md:text-3xl lg:text-4xl leading-5">
        Types Of <span className="text-primary">SAAS Products</span>
      </Typography>
      <div className="grid grid-cols-2 lg:mx-44 md:mx-10 mx-5 md:grid-cols-3 lg:grid-cols-5 gap-6 mt-2 sha">
        {icons.map((item, index) => (
          <div
            key={item.id}
            className={index === icons.length - 1 ? 'col-span-2 w-fit mx-auto md:col-span-1 md:w-full' : ''}
          >
            <TecnologyCard
              imageUrl={item.url}
              key={index}
              title={item.title}
              classNames={`shadow-xl shadow-rose-100 md:py-8  rounded-xl rounded-none ${
                index === icons.length - 1 ? 'px-6' : ''
              }`}
            />
          </div>
        ))}
      </div>
      <div className="mx-auto">
        <Button classNames="bg-primary text-white text-base" icon={'/assets/icons/arrow-narrow-right-white.svg'}>
          Get Service Now
        </Button>
      </div>
    </div>
  );
};

export default BestSolutions;
