import React from 'react';
import { Button } from 'modules/shared/components/atoms';

const WebExcellence = () => {
  return (
    <div>
      <div className="flex flex-col gap-10 place-content-center items-center text-center lg:mx-8 empowering-section z-10 relative">
        <p className="text-2xl lg:text-5xl leading-7 lg:leading-12 tracking-wide lg:tracking-wider lg:mx-8 mx-4 font-bold text-white">
          By choosing our services, you entrust your web development needs to a team committed to excellence,
          innovation, and the proactive advancement of your business objectives.
        </p>
        <Button icon={'/assets/icons/arrow-narrow-right-white.svg'}>Let’s Work Togother</Button>
        <div className="absolute left-0 top-0 -z-10">
          <img src="/assets/icons/ecllipse.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default WebExcellence;
