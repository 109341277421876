import React, { Children } from 'react';
import { EffortlessAWSEvolutionCard } from '../../molecules';

const EffortlessAWSEvolutionData = [
  {
    id: 1,
    imgUrl: '/assets/icons/AWS-Development-Consulting.png',
    title: 'AWS Development Consulting',
    serviceDescription: 'Development, deployment, migration, and optimization on AWS.',
    secondPoint: 'Focus:',
    secondPointDescription: ' Establishing and maintaining high-performance cloud environments',
  },
  {
    id: 2,
    imgUrl: '/assets/icons/AWS-App-Development-Services.png',
    title: 'AWS App Development Services',
    serviceDescription: 'Building cutting-edge web and mobile applications on AWS.',
    secondPoint: 'Focus:',
    secondPointDescription:
      ' Developing applications from scratch or modernizing legacy applications with custom features.',
  },
  {
    id: 3,
    imgUrl: '/assets/icons/AWS-Cloud-Migration-Services.png',
    title: 'AWS Cloud Migration Services',
    serviceDescription:
      'Successful migration to the cloud with a focus on understanding application needs, business objectives, and migration requirements.',
  },
  {
    id: 4,
    imgUrl: '/assets/icons/AWS-Cloud-Migration-Services.png',
    title: 'DevOps AWS Services',
    serviceDescription:
      'DevOps on AWS using automation frameworks like CodePipeline, AWS CodeBuild, and AWS CodeDeploy.',
    secondPoint: 'Expertise:',
    secondPointDescription: 'End-to-end automation for developing, testing, and deploying applications in AWS',
  },
  {
    id: 5,
    imgUrl: '/assets/icons/AWS-Managed-Services.png',
    title: 'AWS Managed Services',
    serviceDescription: ' Cost-optimized, seamless managed services tailored for the modern IT landscape.',
    secondPoint: 'Goal:',
    secondPointDescription:
      ' Helping clients make informed decisions about infrastructure and maximizing the value of existing resources',
  },
];
const CenteredGrid = ({ children }) => {
  const gridItems = Children.toArray(children);
  const lastRow = gridItems.splice(-2, 3);

  return (
    <div className="grid  lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-4">
      <div className="col-span-3  grid lg:grid-cols-3 grid-cols-1 gap-4">{gridItems}</div>
      <div className="grid lg:grid-cols-6 grid-cols-1 justify-center  place-content-center  col-span-3">
        <div className="col-span-4 col-start-2 lg:flex grid gap-4">{lastRow}</div>
      </div>
    </div>
  );
};
const CenteredGrid2 = ({ children }) => {
  const gridItems = Children.toArray(children);
  const lastRow = gridItems.splice(-2, 3);

  return (
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
      {gridItems}
      <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1 justify-center place-content-center mt-4">
        <div className="col-span-4 md:col-span-6 grid lg:flex gap-4">{lastRow}</div>
      </div>
    </div>
  );
};

const EffortlessAWSEvolution = () => {
  return (
    <div className="container">
      <div className="text-center mb-10">
        <p className="text-5xl font-bold leading-12">Seamlessly Cloud Transformation with Our</p>
        <p className="text-5xl font-bold text-primary">AWS Development Expertise.</p>
      </div>
      <CenteredGrid>
        {EffortlessAWSEvolutionData.map((item) => {
          return <EffortlessAWSEvolutionCard {...item} key={item.id} />;
        })}
      </CenteredGrid>
    </div>
  );
};

export default EffortlessAWSEvolution;
