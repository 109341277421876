import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';
const images = [
  '/assets/icons/aws5.png',
  '/assets/icons/aws6.png',
  '/assets/icons/aws7.png',
  '/assets/icons/aws8.png',
  '/assets/icons/aws1.png',
  '/assets/icons/aws2.png',
  '/assets/icons/aws3.png',
  '/assets/icons/aws4.png',
];

const AWSMainSection = () => {
  return (
    <div className="py-14 flex flex-col items-center gap-8">
      <Typography variant="section" className="text-center leading-4">
        AWS Development
      </Typography>
      <div className="text-center">
        <Typography htmlTag="p" variant="title" className="font-medium leading-0">
          Choose the best AWS services and master cloud development with an
        </Typography>
        <Typography htmlTag="p" variant="title" className="font-medium leading-8">
          AWS Advanced Partner.{' '}
        </Typography>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-20">
        <img src="/assets/icons/aws-partner.png" alt="" className="w-36" />
        <span className="hidden md:block border h-44 border-stroke rounded-full"></span>
        <div className="grid grid-cols-4 gap-6">
          {images.map((item) => (
            <img src={item} alt="" className="md:mr-12 w-20" />
          ))}
        </div>
      </div>
      <Button classNames="mt-4 px-8">Book free consultation</Button>
    </div>
  );
};

export default AWSMainSection;
