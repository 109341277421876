import { TecnologyCard, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const icons = [
  { id: 1, title: 'Government', url: 'assets/icons/bank.png' },
  { id: 2, title: 'Education', url: 'assets/icons/education.png' },
  { id: 3, title: 'Healthcare', url: 'assets/icons/first-aid-kit.png' },
  { id: 4, title: 'E-commerce', url: 'assets/icons/shopping.png' },
  {
    id: 5,
    title: 'Fintech',
    url: 'assets/icons/financial-technology.png',
  },
  { id: 6, title: 'Tourism', url: 'assets/icons/flight.png' },
  { id: 7, title: 'Oil and Gas', url: 'assets/icons/pipe.png' },
  { id: 8, title: 'Construction', url: 'assets/icons/hook.png' },
  { id: 9, title: 'Real Estate', url: 'assets/icons/deal.png' },
];

const BestSolutions = () => {
  return (
    <div id="industries" className="container grid gap-4 py-16">
      <Typography variant="section" className="text-center leading-8">
        Best Solutions, For All Organizations
      </Typography>
      <Typography variant="title" className="text-center  md:text-2xl font-medium leading-8 ">
        <span className="text-primary">In Degree</span> Digital Services specializes in the creation ofsoftware
        solutions spanning a wide spectrum of
        <br className="lg:hidden" /> industries, which includes:
      </Typography>
      <div className="flex flex-wrap justify-center lg:mx-16 mx-10 gap-6">
        {icons.map((item, index) => (
          <div key={item.id} className={'w-full md:w-1/4 lg:w-1/6  '}>
            <div
              className={`flex flex-col items-center gap-4 text-center overflow-hidden  shadow-xl shadow-rose-100 py-6 rounded-none `}
            >
              <span className="inline-flex items-center justify-center lg:mt-2 h-14 w-16">
                <img src={item.url} alt="" />
              </span>
              <div className="font-semibold text-2xl md:mb-2">{item.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSolutions;
