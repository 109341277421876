import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../atoms';

const Navbar = () => {
  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const handleButtonClick = (sectionId) => {
    scrollToId(sectionId);
  };
  return (
    <nav className="bg-white font-darker-grotesque py-4 text-xs shadow-xl shadow-rose-50 fixed w-full z-50">
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/" className="font-semibold">
          <img src="/assets/main_logo/indegree_logo.png" alt="Logo" />
        </Link>

        <div className="flex gap-16 text-lg">
          <Link to="/" className="font-semibold">
            Home
          </Link>
          <Link
            onClick={() => {
              handleButtonClick('company');
            }}
            className="font-semibold"
          >
            Company
          </Link>
          <div className="group inline-block ">
            <Link
              onClick={() => {
                handleButtonClick('services');
              }}
              className="font-semibold"
            >
              Services
            </Link>
            {/* <div className="hidden group-hover:block bg-gray-700 absolute space-y-2">
              <Link to="" className="block px-4 py-2">
                Service 1
              </Link>
              <Link to="/services" className="block px-4 py-2">
                Service 2
              </Link>
              <Link to="/contact" className="block px-4 py-2">
                Service 3
              </Link>
            </div> */}
          </div>
          <Link
            onClick={() => {
              handleButtonClick('technologies');
            }}
            className="font-semibold"
          >
            Technologies
          </Link>
          <Link
            onClick={() => {
              handleButtonClick('industries');
            }}
            className="font-semibold"
          >
            Industries
          </Link>
          <Link to="#" className="font-semibold">
            Careers
          </Link>
        </div>

        <Button
          onClick={() => {
            handleButtonClick('footer');
          }}
          variant="primary"
        >
          Contact Us
        </Button>
      </div>
    </nav>
  );
};

// export default Navbar;

// import * as React from 'react';

// import { cn } from '@/lib/utils';
// import {
//   NavigationMenu,
//   NavigationMenuContent,
//   NavigationMenuItem,
//   NavigationMenuLink,
//   NavigationMenuList,
//   NavigationMenuTrigger,
//   navigationMenuTriggerStyle,
// } from '@/components/ui/navigation-menu';
// import { Link } from 'react-router-dom';

// const components = [
//   {
//     title: 'Alert Dialog',
//     href: '/docs/primitives/alert-dialog',
//     description: 'A modal dialog that interrupts the user with important content and expects a response.',
//   },
//   {
//     title: 'Hover Card',
//     href: '/docs/primitives/hover-card',
//     description: 'For sighted users to preview content available behind a link.',
//   },
//   {
//     title: 'Progress',
//     href: '/docs/primitives/progress',
//     description:
//       'Displays an indicator showing the completion progress of a task, typically displayed as a progress bar.',
//   },
//   {
//     title: 'Scroll-area',
//     href: '/docs/primitives/scroll-area',
//     description: 'Visually or semantically separates content.',
//   },
//   {
//     title: 'Tabs',
//     href: '/docs/primitives/tabs',
//     description: 'A set of layered sections of content—known as tab panels—that are displayed one at a time.',
//   },
//   {
//     title: 'Tooltip',
//     href: '/docs/primitives/tooltip',
//     description:
//       'A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.',
//   },
// ];

// function Navbar() {
//   return (
//     <div className="bg-white font-darker-grotesque py-4 text-xs shadow-xl shadow-rose-50 fixed w-full z-50">
//       <div className="container mx-auto flex items-center justify-between">
//         <NavigationMenu>
//           <NavigationMenuList>
//             <NavigationMenuItem>
//               <NavigationMenuItem>
//                 <Link href="/" className="font-semibold">
//                   <img src="/assets/main_logo/indegree_logo.png" alt="Logo" />
//                 </Link>
//               </NavigationMenuItem>
//               <NavigationMenuContent>
//                 <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
//                   <li className="row-span-3">
//                     <NavigationMenuLink asChild>
//                       <a
//                         className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
//                         href="/"
//                       >
//                         <img src="/assets/icons/aws1.png" alt="" />
//                         <div className="mb-2 mt-4 text-lg font-medium">shadcn/ui</div>
//                         <p className="text-sm leading-tight text-muted-foreground">
//                           Beautifully designed components that you can copy and paste into your apps. Accessible.
//                           Customizable. Open Source.
//                         </p>
//                       </a>
//                     </NavigationMenuLink>
//                   </li>
//                   <ListItem href="/docs" title="Introduction">
//                     Re-usable components built using Radix UI and Tailwind CSS.
//                   </ListItem>
//                   <ListItem href="/docs/installation" title="Installation">
//                     How to install dependencies and structure your app.
//                   </ListItem>
//                   <ListItem href="/docs/primitives/typography" title="Typography">
//                     Styles for headings, paragraphs, lists...etc
//                   </ListItem>
//                 </ul>
//               </NavigationMenuContent>
//             </NavigationMenuItem>
//             <NavigationMenuItem>
//               <NavigationMenuTrigger>Components</NavigationMenuTrigger>
//               <NavigationMenuContent>
//                 <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
//                   {components.map((component) => (
//                     <ListItem key={component.title} title={component.title} href={component.href}>
//                       {component.description}
//                     </ListItem>
//                   ))}
//                 </ul>
//               </NavigationMenuContent>
//             </NavigationMenuItem>
//             <NavigationMenuItem>
//               <Link href="/newapfe" legacyBehavior passHref>
//                 <NavigationMenuLink className={navigationMenuTriggerStyle()}>Documentation</NavigationMenuLink>
//               </Link>
//             </NavigationMenuItem>
//           </NavigationMenuList>
//         </NavigationMenu>
//       </div>
//     </div>
//   );
// }

// const ListItem = React.forwardRef(({ className, title, children, ...props }, ref) => {
//   return (
//     <li>
//       <NavigationMenuLink asChild>
//         <a
//           ref={ref}
//           className={cn(
//             'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
//             className,
//           )}
//           {...props}
//         >
//           <div className="text-sm font-medium leading-none">{title}</div>
//           <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
//         </a>
//       </NavigationMenuLink>
//     </li>
//   );
// });
// ListItem.displayName = 'ListItem';

export default Navbar;
