// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../public/assets/images/data_engineering.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../../public/assets/images/data_engineering_phone.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-wrapper {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #373737;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .banner-wrapper {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-color: #373737;
    background-size: cover;
    background-position: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/data-engineering/components/organisms/DataEngineeringBanner/style.css"],"names":[],"mappings":"AAAA;EACE,yDAAoF;EACpF,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE;IACE,yDAA0F;IAC1F,yBAAyB;IACzB,sBAAsB;IACtB,2BAA2B;EAC7B;AACF","sourcesContent":[".banner-wrapper {\n  background-image: url('../../../../../../public/assets/images/data_engineering.png');\n  background-color: #373737;\n  background-size: cover;\n  background-position: center;\n}\n\n@media only screen and (max-width: 768px) {\n  .banner-wrapper {\n    background-image: url('../../../../../../public/assets/images/data_engineering_phone.png');\n    background-color: #373737;\n    background-size: cover;\n    background-position: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
