import { Button } from 'modules/shared/components/atoms';
import React from 'react';

const RevitalizeApp = () => {
  return (
    <div className="font-darker-grotesque grid items-center lg:grid-cols-5 md:grid-cols-2">
      <div className="lg:col-span-2 col-span-1">
        <img src="/assets/images/application-modernization.png" alt="" />
      </div>
      <div className="lg:col-span-3 md:col-span-1 grid lg:gap-8 gap-3 ">
        <p className="lg:leading-12 lg:text-5xl text-2xl tracking-wider font-bold  capitalize lg:pr-40">
          Revitalize your app for growth with <span className="text-primary">our expertise</span>
        </p>
        <p className="lg:text-2xl text-xl lg:leading-8 text-secondary lg:pr-44 font-medium">
          Quickly deploy our comprehensive app modernization services ,designed to rectify, enhance, and rejuvenate your
          systems. Our seasoned teams ,with experience across 50 companies, ensure a swift and effective transformation.
        </p>
        <Button
          icon={'/assets/icons/arrow-narrow-right-white.svg'}
          classNames="text-white bg-primary lg:w-1/4 w-2/3 lg:px-0 px-2 lg:py-2.5"
        >
          Get Service Now
        </Button>
      </div>
    </div>
  );
};

export default RevitalizeApp;
