import React from 'react';
import { Button } from 'modules/shared/components/atoms';
import { Typography } from 'modules/shared/components/atoms';
import DataTransformationModule from '../../molecules/DataTransformationModule';
const transformationModules = [
  { title: 'ETL / ELT', iconUrl: '/assets/images/transformation/elt.png' },
  {
    title: 'Data Migration',
    iconUrl: '/assets/images/transformation/data_migration.png',
  },
  {
    title: 'Data lake/ Warehouse',
    iconUrl: '/assets/images/transformation/data_lake.png',
  },
  {
    title: 'Stream processing',
    iconUrl: '/assets/images/transformation/stram_processing.png',
  },
];
const OperationsTransformations = () => {
  return (
    <div className="flex flex-col gap-4 w-full m-auto rounded-md bg-gray-100 px-2  py-8">
      <div className="text-center text-3xl lg:text-5xl font-bold leading-9 lg:leading-12">
        <span className="">Transform your operations with the </span>
        <span className="text-primary">data you already have</span>
      </div>
      <p className="text-center text-xl md:text-2xl font-medium text-secondary mx-2 lg:mx-auto lg:px-16 lg:w-4/5 m-auto">
        No matter the web applications you're building, we're here to support you at every stage of Apps and Web
        development . Our team of the designers and developers has built and served over 80 companies globally — all
        aligned with each client's digital marketing strategy.
      </p>
      <div className="grid grid-cols-1 mx-14 gap-4 md:grid-cols-2 lg:grid-cols-4 md:gap-4 my-4 px-0 lg:px-14">
        {transformationModules.map(({ title, iconUrl }) => {
          return <DataTransformationModule title={title} iconUrl={iconUrl} />;
        })}
      </div>
      <div className="text-center">
        <Button
          icon={'/assets/icons/arrow-narrow-right-white.svg'}
          classNames="bg-primary text-white w-fit mx-auto tracking-wide py-2.5 px-8"
        >
          Get Service Now
        </Button>
      </div>
    </div>
  );
};

export default OperationsTransformations;
