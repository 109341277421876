import React from 'react';
import { BasicCard } from 'modules/home/components/molecules';
import { Typography } from 'modules/shared/components/atoms';

const exampleData = [
  {
    id: 1,
    icon: '/assets/icons/highlights/rocket.svg',
    title: 'Complete Product Teams',
    description:
      'Gain access to a full spectrum of web development professionals, from Business Analysts to Full-stack Developers, ensuring a comprehensive approach to project execution.',
  },
  {
    id: 2,
    icon: '/assets/icons/highlights/rocket_iteration.svg',
    title: 'Rapid Iterations and Frequent Releases',
    description:
      'Leveraging pre-built components and proprietary templates ,our experienced team delivers significant progress in each sprint, enhancing project momentum.',
  },
  {
    id: 3,
    icon: '/assets/icons/highlights/business_architecture.svg',
    title: 'Customized Business Architecture',
    description:
      'Our solutions are meticulously designed with your business growth and product scalability in mind, ensuring a foundation for success. ',
  },
  {
    id: 4,
    icon: '/assets/icons/highlights/quality_assurance.svg',
    title: 'Rigorous Quality Assurance',
    description:
      'We ensure product integrity through rigorous QA protocols, combining manual and automated testing to identify and address bugs, performance issues, and conversion obstacles prior to deployment',
  },
  {
    id: 5,
    icon: '/assets/icons/highlights/secure.svg',
    title: 'Scalable and Secure Infrastructure',
    description:
      'Our web development services are tailored to provide custom solutions that prioritize efficiency, flexibility, and speed in deployment ,ensuring your infrastructure can grow with your business.',
  },
  {
    id: 6,
    icon: '/assets/icons/highlights/ui_ux.svg',
    title: 'World-Class UX/UIDesign',
    description:
      "We focus on creating user experiences and interfaces thatfoster user adoption and satisfaction, ultimately contributing to yourbusiness's success.",
  },
];
const KeyHighlights = () => {
  return (
    <div>
      <div>
        <div className="text-center text-2xl lg:text-5xl font-bold leading-8 lg:leading-12">
          <span className="">Key Highlights of Our</span>
          <span className="text-primary">Apps & Web Development Services</span>
        </div>
      </div>
      <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-6  my-8">
        {exampleData.map((item) => (
          <BasicCard key={item.id} icon={item.icon} title={item.title} description={item.description} />
        ))}
      </div>
    </div>
  );
};

export default KeyHighlights;
