import React from 'react';
import { Button, Typography } from 'modules/shared/components/atoms';
const imgPath = '/assets/images/ai-banner1.jpeg';

const AiMLlanner = () => {
  return (
    <div>
      <div className="h-16"></div>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(55, 55, 55, 0.73), rgba(55, 55, 55, 0.73)), url(${imgPath})`,
        }}
        className="mt-3 font-darker-grotesque w-full bg-no-repeat bg-cover bg-center bg-[#201717] bg-ai-banner"
      >
        <div className="flex flex-col md:gap-10 gap-4 place-content-center items-center text-center md:py-20 py-10">
          <Typography
            htmlTag="h1"
            variant="section"
            className="text-white text-xl leading-5 md:leading-10 tracking-wider md:text-5xl font-bold "
          >
            Artificial Intelligence(AI) and Machine Learning (ML)
          </Typography>
          <Typography
            htmlTag="p"
            variant="description"
            className="text-white text-sm md:text-lg lg:w-2/3 w-4/5 md:tracking-wider tracking-normal"
          >
            We harness the power of Artificial Intelligence (AI) and Machine Learning (ML) to provide cutting-edge
            solutions. Our expertise translates your AI vision into reality, offering intelligent and data-driven
            insights. We specialize in Image & Video, Text-to-Speech, Business Intelligence, Data Forecasting, Natural
            Language Processing, and Data Analytics, empowering your business with valuable present and
            futurepredictions.
          </Typography>
          <Button classNames="bg-primary text-white text-base">Book free consultation</Button>
        </div>
      </div>
    </div>
  );
};

export default AiMLlanner;
