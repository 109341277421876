import { Typography } from 'modules/shared/components/atoms';
import React from 'react';
import { AIMLServicesCard } from '../../molecules';

const leftSideDATA = [
  {
    id: 1,
    title: 'Recommendation Engine',
    description:
      'Boost sales and enhance user experience with personalized suggestions in sectors like e-commerce and lifestyle.',
  },
  {
    id: 2,
    title: 'Process Automation',
    description: 'Optimize resources, save time, and improve cost-effectiveness by automating repetitive tasks.',
  },
  {
    id: 3,
    title: 'Voice-based AI',
    description: 'Create personalized AI assistants like Siri and Alexa to engage customers dynamically.',
  },
];
const rightSideDATA = [
  {
    id: 4,
    title: 'Chatbots',
    description: 'Deploy AI-enabledChatbots for engaging and 24/7 query resolution',
  },
  {
    id: 5,
    title: 'Inventory Optimization',
    description:
      'Predict sales, streamline production, and improve supply chain processes for enhanced business productivity.',
  },
  {
    id: 6,
    title: 'Image Processing',
    description: 'Utilize AI and ML for accurate image recognition, widely used in medical and security sectors.',
  },
];
const AIMLServices = () => {
  return (
    <div>
      <div className="text-center mb-10">
        <Typography htmlTag="span" variant="section" className="text-cente text-primary ">
          AI & ML
        </Typography>
        <Typography htmlTag="span" variant="section" className="text-center">
          Services We Offer:
        </Typography>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="place-content-center gap-10 md:hidden block">
          <div className="mx-auto">
            <img src="/assets/images/robot.png" alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          {leftSideDATA.map((data) => (
            <AIMLServicesCard key={data.id} num={data.id} title={data.title} description={data.description} />
          ))}
        </div>
        <div className="place-content-center gap-10 hidden md:block">
          <div className="mx-auto">
            <img src="/assets/images/robot.png" alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          {rightSideDATA.map((data) => (
            <AIMLServicesCard key={data.id} num={data.id} title={data.title} description={data.description} />
          ))}
        </div>
      </div>
      <div className="lg:w-1/3 m-auto md:mt-2 mt-4">
        <AIMLServicesCard
          num={'7'}
          title={'Object Recognition'}
          description={
            'Apply AI, ML, andneural networks for smart search engines, identifying objects in images, andmore'
          }
        />
      </div>
    </div>
  );
};

export default AIMLServices;
