// ReusableCard.js
import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const BasicCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-4 rounded-3xl shadow-xl shadow-rose-100 border border-stroke font-darker-grotesque pr-10">
      <div className="flex gap-2 items-center mb-4">
        {icon && <img src={icon} alt="icon" />}
        <p className="text-xl font-bold">{title}</p>
      </div>
      <p className="">{description}</p>
    </div>
  );
};

export default BasicCard;
