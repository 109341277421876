import React from 'react';

const CloudMastery = () => {
  const images = [
    '/assets/icons/aws1.png',
    '/assets/icons/aws2.png',
    '/assets/icons/aws3.png',
    '/assets/icons/aws4.png',
    '/assets/icons/aws5.png',
    '/assets/icons/aws6.png',
    '/assets/icons/aws7.png',
    '/assets/icons/aws8.png',
  ];

  return (
    <div className="font-darker-grotesque rounded-xl bg-gray-100 md:py-8 py-3 lg:my-16 md:my-10 my-4 ">
      <div className="lg:w-9/12 w-11/12 mx-auto grid gap-8 ">
        <div className="flex items-center justify-center p-4">
          <img src="/assets/icons/aws-partner.png" alt="" />
        </div>
        <div className="flex flex-col items-center text-center gap-4">
          <span className="font-bold lg:text-5xl md:text-2xl text-xl mb-2">
            Work with an official AWS Advanced Partner
          </span>
          <p className="text-secondary text-xl font-semibold lg:px-40">
            Leveraging the expertise of an accredited AWS Advanced Partner. Stay competitive with industry leaders in
            Cloud Ops. Optimize expenses, enhance observability, and select the most suitable services for your needs.
          </p>
        </div>

        <div className="grid lg:grid-cols-8 md:grid-cols-4 md:gap-4 gap-4 place-items-center grid-cols-2 content-center ">
          {images.map((image, index) => (
            <img src={image} alt="" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CloudMastery;
