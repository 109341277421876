import React from 'react';
import { TecnologyCard } from 'modules/shared/components/atoms';

const Tecnologiesdata = [
  { id: 1, title: '.NET', icon: '/assets/icons/dotnet-icon.png' },
  { id: 2, title: 'React js', icon: '/assets/icons/react-icon.png' },
  { id: 3, title: 'Next', icon: '/assets/icons/next-icon.png' },
  { id: 4, title: 'JavaScript', icon: '/assets/icons/js-icon.png' },
  { id: 5, title: 'Python', icon: '/assets/icons/python-icon.png' },
  { id: 6, title: 'Java', icon: '/assets/icons/java-icon.png' },
  { id: 7, title: 'Figma', icon: '/assets/icons/figma-icon.png' },
  { id: 8, title: 'Node js', icon: '/assets/icons/node-icon.png' },
  {
    id: 9,
    title: 'Microservices',
    icon: '/assets/icons/microservices-icon.png',
  },
  { id: 10, title: 'Serverless', icon: '/assets/icons/serverless-icon.png' },
  { id: 11, title: 'Kubernetes', icon: '/assets/icons/kubernetes-icon.png' },
  { id: 12, title: 'Docker', icon: '/assets/icons/docker-icon.png' },
  { id: 13, title: 'VMware', icon: '/assets/icons/vmware-icon.png' },
  { id: 14, title: 'GO', icon: '/assets/icons/go-icon.png' },
];
const TecnologiesList = () => {
  return (
    <div id="technologies" className="lg:container">
      <div className="lg:w-10/12 mx-auto grid gap-4 lg:my-20 my-8 items-center text-center">
        <span className="text-center text-2xl md:text-4xl font-bold md:leading-12 lg:tracking-wide">
          Our Tech stack for competitive, cloud-native products is built on scalable and{' '}
          <span className="text-primary">Flexible Technologies Mirroring Your Choices.</span>
        </span>
        <span className="text-center text-secondary font-semibold tracking-wide lg:tracking-normal text-xl lg:text-2xl lg:w-8/12 mx-auto">
          Our engineers, with their solution-oriented mindset, have successfully navigated the challenges presented by
          more than 80 clients.
        </span>
      </div>
      <div className="hidden lg:flex flex-col my-10 items-center">
        <h2 className="lg:text-3xl md:text-2xl text-xl  font-bold text-center tracking-wide w-fit">
          Technologies and Tools
        </h2>
        <span className="text-center w-44 md:w-56 lg:w-64 border-b-4 mt-2 border-primary"></span>
      </div>

      <div className="flex flex-wrap justify-center gap-5 lg:gap-8">
        {Tecnologiesdata.map((item, index) => (
          <div key={index}>
            <TecnologyCard
              imageUrl={item.icon}
              title={item.title}
              key={item.id}
              classNames="bg-gray-100 w-16  md:w-40 rounded-xl lg:w-40"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TecnologiesList;
