import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from 'modules/home/page';
import { Development } from 'modules/development/page';
import { Modernization } from 'modules/modernization/page';
import { DataEngineering } from 'modules/data-engineering/page';
import { AwsDevelopment } from 'modules/aws-development/page';
import { SassDevelopment } from 'modules/sass-development/page';
import { ArtificialIntelligence } from 'modules/artificial-intelligence/page';
import { Layout } from 'modules/shared/components/template';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="services/web-mobile-development" element={<Development />} />
          <Route path="services/modernization" element={<Modernization />} />
          <Route path="services/artificial-intelligence" element={<ArtificialIntelligence />} />
          <Route path="services/data-engineering" element={<DataEngineering />} />
          <Route path="services/aws-development" element={<AwsDevelopment />} />
          <Route path="services/sass-development" element={<SassDevelopment />} />
        </Route>
        <Route path="*" element="Not found" />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
