import { isProduction } from 'modules/shared/config/constants';
import React from 'react';
import { Helmet } from 'react-helmet';
import { domainUrl } from 'modules/shared/config/constants';
const SeoMetaTags = ({ title, description, keywords, image, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {isProduction ? <meta name="robots" content="index, follow" /> : ''}
      <meta name="author" content="Indegree" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#000000" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={domainUrl} />
      <link rel="canonical" href={domainUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Indegree" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Indegree" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SeoMetaTags;
