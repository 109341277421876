import React from 'react';
import { AIMLServices, AIPowered, AIStructure, AiMLBanner, WhyAIMLServices } from '../component/organisms';
import { ScrollToTop } from 'modules/shared/components/molecules';
import { SeoMetaTags } from 'modules/shared/components/organisms';

const ArtificialIntelligence = () => {
  return (
    <>
      <SeoMetaTags
        title="Indegree | Artificial Intelligence"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="grid gap-16">
        <AiMLBanner />
        <div className="container grid sm:gap-8 md:14 lg:gap-28">
          <AIMLServices />
          <WhyAIMLServices />
          <AIStructure />
          <AIPowered />
        </div>
        <ScrollToTop />
      </div>
    </>
  );
};

export { ArtificialIntelligence };
