import React from 'react';
import { EnhanceModernization, ModernizationCard, RevitalizeApp } from 'modules/modernization/components/organisms';
import { TecnologiesList, SubscribeCard, SeoMetaTags } from 'modules/shared/components/organisms';
import { Button } from 'modules/shared/components/atoms';
import { ScrollToTop } from 'modules/shared/components/molecules';

const Modernization = () => {
  return (
    <div className="container">
      <SeoMetaTags
        title="Indegree | Modernization"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="pt-28">
        <ModernizationCard />
        <RevitalizeApp />
        <div className="lg:pt-28 pt-10 grid gap-10  md:gap-28">
          <EnhanceModernization />
          <TecnologiesList />
          <div className="">
            <SubscribeCard
              title="Subscribe to our newsletter and join the innovation journey"
              description="Stay in touch with our team here at In Degree Stay ahead of the curve with our software updates, industry insights, and special offers"
            >
              <div className="w-full">
                <form className="md:flex grid grid-cols-2 gap-4 bg-white rounded-full p-1 ">
                  <input
                    type="text"
                    className="rounded-full md:w-80 w-40 pl-2 outline-none"
                    placeholder="Write Your Mail"
                  />
                  <Button classNames="bg-primary text-white px-8">Subscribe</Button>
                </form>
              </div>
            </SubscribeCard>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export { Modernization };
