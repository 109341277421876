import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const DataMigration = () => {
  return (
    <div className="flex flex-col gap-6">
      <p variant="section" className="text-2xl lg:text-5xl font-bold text-center leading-9 px-5">
        DATA MIGRATION & RESTRUCTURE
      </p>
      <div className="">
        <img className="object-contain m-auto w-full" src="/assets/images/migration.png" alt="" />
      </div>
    </div>
  );
};

export default DataMigration;
