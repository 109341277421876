import { Button } from 'modules/shared/components/atoms';
import React from 'react';

const WhyIndegree = () => {
  return (
    <div id="company" className="grid lg:grid-cols-5 grid-cols-1 md:gap-10 lg:py-24">
      <div className="col-span-2 flex flex-col gap-4">
        <div className="flex flex-col md:flex-row items-start justify-start md:justify-center gap-6">
          <img src="/assets/images/why-indegree1.png" className="h-64 md:block hidden" alt="" />
          <img src="/assets/images/why-indegree1_phone.png" className="md:hidden " alt="" />
          <img src="/assets/images/why-indegree2.png" className="h-64" alt="" />
        </div>
        <div className="relative flex justify-end md:mr-20 lg:mr-0">
          <span className="lg:absolute md:-right-5">
            <img src="/assets/images/why-indegree3.png" alt="" />
          </span>
          <span className="absolute  -bottom-20 md:bottom-12 md:left-20 left-24 lg:left-0 lg:top-12">
            <img src="/assets/images/in-logo.png" alt="" />
          </span>
        </div>
      </div>
      <div className="col-span-3 lg:px-12 md:px-3  py-8">
        <div className="mt-16 md:mt-0">
          <h2 className="lg:text-4xl md:text-3xl text-3xl text-center lg:text-start font-bold md:mb-4">
            Why <span className="text-primary">In Degree?</span>
          </h2>
          <p className="text-2xl text-center lg:text-start font-semibold md:leading-7">
            Choose Us for Exceptional Software Solutions: Experience Innovation ,Quality, and Reliability
          </p>
          <div className="md:pr-16 pr-4 mt-4 lg:tracking-wide text-2xl font-medium text-center lg:text-start text-secondary leading-7">
            <p className="">
              In Degree Digital Services, established in 2020, emerged from the collaboration of a group of seasoned
              software developers and adept business executives with a notable presence in Qatar, Egypt, and KSA.
            </p>
            <p className="hidden lg:block">
              Subsequently, the company has witnessed remarkable expansion, solidifying its position as a premier
              provider of bespoke software solutions tailored to businesses of diverse scales within the region, with a
              primary focus on harnessing the capabilities of artificial intelligence.
            </p>
          </div>
          <div className="mt-4 lg:mt-8 flex justify-center lg:justify-start">
            <Button variant="ghost" icon={'assets/icons/arrow-narrow-right.svg'}>
              More About Us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyIndegree;
