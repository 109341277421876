import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const WhySaas = () => {
  return (
    <div className="flex flex-col items-center gap-8 w-full  m-auto">
      <Typography variant="section" className="text-center text-xl md:text-3xl lg:text-4xl leading-5">
        WHY SAAS?
      </Typography>
      <div className="mx-auto">
        <img src="/assets/images/Image saas-benefits.png" alt="" />
      </div>
      <Typography variant="section" className="text-center text-xl md:text-3xl lg:text-4xl leading-5">
        Steps to succeed <span className="text-primary">SAAS</span>
      </Typography>
    </div>
  );
};

export default WhySaas;
