import React from 'react';
import './style.css';
const SubscribeCard = ({ title, description, children }) => {
  return (
    <div className="subscribe bg-primary rounded-xl p-8 px-5 mb-8">
      <div className="flex flex-col items-center lg:w-8/12 mx-auto text-center tracking-wide">
        <h2 className="lg:text-5xl text-2xl text-white font-bold mb-4 capitalize lg:leading-12">{title}</h2>
        <p className="text-white mb-8 md:w-3/4 leading-7 font-medium text-xl lg:tracking-wide">{description}</p>
        <div className="md:px-4">{children}</div>
      </div>
    </div>
  );
};

export default SubscribeCard;
