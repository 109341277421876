import React from 'react';
import {
  WebAppDevelopmentCard,
  DevelopmentTeamHighlight,
  OnlinePresenceBooster,
  KeyHighlights,
} from 'modules/development/components/organisms';
import { TecnologiesList, SubscribeCard, SeoMetaTags } from 'modules/shared/components/organisms';
import { Button } from 'modules/shared/components/atoms';
import { WebExcellence } from '../components/molecules';
import { ScrollToTop } from 'modules/shared/components/molecules';
import { Helmet } from 'react-helmet';

const Development = () => {
  return (
    <>
      <SeoMetaTags
        title="Indegree | Apps and Web Development"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="">
        <div className="h-28"></div>
        <main className="container">
          <WebAppDevelopmentCard />
          <div className="lg:mt-24 mt-10 mb-5">
            <DevelopmentTeamHighlight />
          </div>
          <div className="my-8">
            <OnlinePresenceBooster />
          </div>
          <div className="my-8">
            <KeyHighlights />
          </div>
          <div className="my-8 relative">
            <WebExcellence />
            <div className="absolute hidden lg:flex items-center  left-[-62px] top-0 bottom-0 z-[-1]">
              <img src="/assets/images/output-onlinepngtools.png" alt="" />
            </div>
            <div className="absolute hidden lg:flex items-center right-[-62px] top-0 bottom-0 z-[-1]">
              <img src="/assets/images/output-onlinepngtools.png" alt="" />
            </div>
          </div>
          <div className="">
            <TecnologiesList />
          </div>
          <div className="container md:my-28 my-2">
            <SubscribeCard
              title="Subscribe to our newsletter and join the innovation journey"
              description="Stay in touch with our team here at In Degree Stay ahead of the curve with our software updates, industry insights, and special offers"
            >
              <div className="">
                <form className="flex bg-white rounded-full p-1 ">
                  <input
                    type="text"
                    className="rounded-full md:w-80 w-40 pl-2 outline-none"
                    placeholder="Write Your Mail"
                  />
                  <Button classNames="bg-primary text-white px-8">Subscribe</Button>
                </form>
              </div>
            </SubscribeCard>
          </div>
        </main>
        <ScrollToTop />
      </div>
    </>
  );
};

export { Development };
