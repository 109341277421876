import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const EnhanceModernization = () => {
  return (
    <div>
      <div className="font-darker-grotesque md:px-20 text-center">
        <p className="text-center font-bold text-2xl md:text-3xl lg:text-5xl leading-8 lg:leading-12">
          Enhance the efficiency and scalability of your systems with our expertise in{' '}
          <span className="text-primary">App modernization.</span>
        </p>
      </div>
      <div className="mt-5">
        <img src="/assets/images/itemeditor.png" alt="" width={'100%'} height={'auto'} />
      </div>
    </div>
  );
};

export default EnhanceModernization;
