import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const AIStructure = () => {
  return (
    <div className="flex flex-col gap-6">
      <Typography variant="section" className="text-center mb-6">
        AI System Structure
      </Typography>
      <div className="">
        <img className="object-contain m-auto w-full" src="/assets/images/ai-structure.png" alt="" />
      </div>
    </div>
  );
};

export default AIStructure;
