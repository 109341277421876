import React from 'react';

const EffortlessAWSEvolutionCard = ({ imgUrl, title, serviceDescription, secondPoint, secondPointDescription }) => {
  return (
    <div className="shadow-xl border border-stroke rounded-3xl p-7 mx-2">
      <div className="flex items-center gap-2">
        <img src={imgUrl} alt="" />
        <h1 className="text-xl font-bold">{title}</h1>
      </div>
      <div className="">
        <span className="text-xl font-semibold">Services: </span>
        <span className="text-secondary text-xl font-medium tracking-wide">{serviceDescription}</span>
      </div>
      <div>
        <span className="text-xl font-semibold">{secondPoint} </span>
        <span className="text-secondary text-xl font-medium tracking-wide">{secondPointDescription}</span>
      </div>
    </div>
  );
};

export default EffortlessAWSEvolutionCard;
