import React from 'react';

const LocationCard = ({ location, address, phone }) => {
  return (
    <div className="bg-white pl-2 pr-16 py-3 rounded-lg mb-4">
      <div className="mb-1">
        <div className="flex gap-4 mr-4">
          <img src="/assets/icons/location2.png" alt="Location Icon" className="w-6 h-6" />
          <h3 className="text-primary">{location}</h3>
        </div>
        <div>
          <p className="ml-10 mt-1">
            {address} <span className="-tracking-widest">..............</span>
          </p>
        </div>
      </div>
      <div>
        <div className="flex gap-4 mr-4">
          <img src="/assets/icons/phone.png" alt="Phone Icon" className="w-6 h-6" />
          <h3 className="text-primary">Phone</h3>
        </div>
        <div>
          <p className="ml-10 mt-1">{phone}</p>
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
