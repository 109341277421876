import React from 'react';
import {
  DataEngineeringBanner,
  OperationsTransformations,
  DataMigration,
} from 'modules/data-engineering/components/organisms';
import { SeoMetaTags, SubscribeCard, TecnologiesList } from 'modules/shared/components/organisms';
import { Button } from 'modules/shared/components/atoms';
import { ScrollToTop } from 'modules/shared/components/molecules';

const DataEngineering = () => {
  return (
    <div>
      <SeoMetaTags
        title="Indegree | Data Engineering"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <DataEngineeringBanner />
      <div className="my-14 md:container">
        <OperationsTransformations />
      </div>
      <main className="container">
        <div className="my-14">
          <DataMigration />
        </div>
        <div className="my-14">
          <TecnologiesList />
        </div>
        <div className="my-14">
          <SubscribeCard
            title="Subscribe to our newsletter and join the innovation journey"
            description="Stay in touch with our team here at In Degree Stay ahead of the curve with our software updates, industry insights, and special offers"
          >
            <div className="">
              <form className="flex bg-white rounded-full p-1 ">
                <input
                  type="text"
                  className="rounded-full md:w-80 w-40 pl-2 outline-none"
                  placeholder="Write Your Mail"
                />
                <Button classNames="bg-primary text-white px-8">Subscribe</Button>
              </form>
            </div>
          </SubscribeCard>
        </div>
      </main>
      <ScrollToTop />
    </div>
  );
};

export { DataEngineering };
