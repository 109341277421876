import React from 'react';
import { Link } from 'react-router-dom';
const ServiceCatalog = ({ icon, title, description, link }) => {
  return (
    <div className="grid px-6 pt-6 border-2 cursor-pointer rounded-xl hover:border-primary transition-all duration-300">
      {icon && <img src={icon} className="material-icons text-4xl mb-4" alt="title" />}
      <h2 className="text-lg font-bold mb-2 pr-10">{title}</h2>
      <p className="text-gray-600 text-lg mb-4">{description}</p>
      <div className="my-2">
        <Link to={link} className="text-primary font-semibold flex gap-2 leading-[1]">
          Discover Now
          <img className="leading-[18px]" height="18px" width="18px" src="/assets/icons/arrow-narrow-right.svg" />
        </Link>
      </div>
    </div>
  );
};

export default ServiceCatalog;
