import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const industriesData = [
  {
    id: 1,
    title: 'Healthcare',
  },
  {
    id: 2,
    title: 'Education',
  },
  {
    id: 3,
    title: 'Media & Entertainment',
  },
  {
    id: 4,
    title: 'E-Commerce',
  },
  {
    id: 5,
    title: 'Food Tech',
  },
  {
    id: 6,
    title: 'Logistics',
  },
  {
    id: 7,
    title: 'Manufacturing',
  },
  {
    id: 8,
    title: 'Sports',
  },
  {
    id: 9,
    title: 'Banking & Finance',
  },
];
const AIPowered = () => {
  return (
    <div>
      <div className="text-center mb-14">
        <Typography htmlTag="p" variant="section" className="text-cente tracking-wide">
          Leverage AI And ML Solutions For Business
        </Typography>
        <Typography htmlTag="span" variant="section" className="text-center text-primary">
          Transformation With Us
        </Typography>
      </div>
      <article className="grid gap-6 grid-cols-1 lg:grid-cols-2  ">
        <section className="flex flex-col gap-8 place-content-center">
          <Typography htmlTag="p" variant="title" className="font-bold text-3xl">
            Industries We Serve :
          </Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {industriesData.map(({ id, title }) => {
              return (
                <article key={id} className="flex items-center">
                  <img src="/assets/icons/check-verified.png" alt="" className="mr-2" />
                  <Typography htmlTag="span" variant="title" className="text-secondary font-medium leading-8">
                    {title}
                  </Typography>
                </article>
              );
            })}
          </div>
          <Button
            icon={'/assets/icons/arrow-narrow-right-white.svg'}
            classNames="bg-primary text-white w-fit  tracking-wide"
          >
            Get Service Now
          </Button>
        </section>
        <div className="self-center  ">
          <img className="m-auto object-contain rounded-lg" src="/assets/images/AI_Journey.png" alt="" />
        </div>
      </article>
    </div>
  );
};

export default AIPowered;
