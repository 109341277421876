// SimpleCard.js

import React from "react";

const SimpleCard = ({ imageUrl, title, classNames = "rounded-xl" }) => {
  return (
    <div
      className={`flex flex-col items-center gap-4 text-center py-2  overflow-hidden  ${classNames}`}
    >
      <span className="inline-flex items-center justify-center lg:mt-2 h-14 w-10 md:w-12 lg:w-16">
        <img src={imageUrl} alt="" />
      </span>
      <div className="hidden md:block font-bold md:text-xl text-base md:mb-2">
        {title}
      </div>
    </div>
  );
};

export default SimpleCard;
