import React from 'react';
import { Button } from 'modules/shared/components/atoms';
import { Typography } from 'modules/shared/components/atoms';
const SaasDevelopmentCard = () => {
  return (
    <div className="flex flex-col items-center gap-8 w-full md:w-1/2 m-auto">
      <Typography htmlTag="h2" variant="section" className="text-center">
        SAAS Products Development
      </Typography>
      <Typography htmlTag="p" variant="title" className="text-center font-medium tracking-wide leading-7">
        Choosing a Software as a Service (SaaS) model for software delivery offers several advantages that make it a
        compelling choice for both businesses and users.
      </Typography>
      <img src="/assets/icons/saas-development-icon.png" alt="" />
      <div className="text-center">
        <Button classNames="bg-primary text-white text-base">Book free consultation</Button>
      </div>
    </div>
  );
};

export default SaasDevelopmentCard;
