// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/assets/images/Home_page.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/assets/images/data_engineering.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../public/assets/images/data_engineering_phone.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_section {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.empowering-section {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  height: 367px;
  border-radius: 12px;
  background-color: #252424;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .empowering-section {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  }
}
@media only screen and (max-width: 820px) {
  .banner_section {
    background-image: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/home/page/styles.css"],"names":[],"mappings":"AAAA;EACE,yDAAuE;EACvE,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B;AACA;EACE,yDAA8E;EAC9E,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE;IACE,yDAAoF;EACtF;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".banner_section {\n  background-image: url('../../../../public/assets/images/Home_page.png');\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n.empowering-section {\n  background-image: url('../../../../public/assets/images/data_engineering.png');\n  background-repeat: no-repeat;\n  height: 367px;\n  border-radius: 12px;\n  background-color: #252424;\n  background-size: cover;\n}\n@media only screen and (max-width: 768px) {\n  .empowering-section {\n    background-image: url('../../../../public/assets/images/data_engineering_phone.png');\n  }\n}\n@media only screen and (max-width: 820px) {\n  .banner_section {\n    background-image: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
