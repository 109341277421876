import { Button, Typography } from 'modules/shared/components/atoms';
import React from 'react';

const optimizeDAta = [
  {
    id: 1,
    title: 'Expert Cloud Engineers',
    description:
      'Benefit from our highly-trained cloud engineers who understand optimal AWS service utilization to ensure cost-effective and operational services.',
    icon: '/assets/images/optimize1.png',
    num: '01',
  },
  {
    id: 2,
    title: 'Scalable Infrastructure Setup',
    description:
      'Redesign your infrastructure with Docker, Kubernetes, AWS, and certified cybersecurity tools to ensure a live, fast, and well-maintained product for millions of end users.',
    icon: '/assets/images/optimize2.png',
    num: '02',
  },
  {
    id: 3,
    title: 'Unlocking AWS Potential',
    description:
      'Explore the full potential of AWS services by integrating technologies like Serverless, AWS Lambda, or S3, enabling the rapid launch of market-ready MVPs or seamless product upgrades with top-notch performance.',
    icon: '/assets/images/optimize3.png',
    num: '03',
  },
  {
    id: 4,
    title: 'Cost and Resource Optimization',
    description:
      'Optimize architecture and services to witness a significant drop in cloud infrastructure costs. One client achieved a 93% cost reduction by consolidating 1000 Wordpress websites into a single Next.js app.',
    icon: '/assets/images/optimize4.png',
    num: '04',
  },
];
const PartnerOptimize = () => {
  return (
    <div className="container ">
      <div className="flex flex-col md:gap-8 place-content-center items-center text-center mb-4 md:mb-24">
        <Typography htmlTag="span" variant="section" className="text-xl leading-5 md:leading-4  md:text-5xl font-bold ">
          Optimize Your AWS Cloud Utilization <span className="text-primary">Within Weeks.</span>
        </Typography>
        <p className="md:text-2xl text-secondary leading-7 md:tracking-wider font-semibold lg:w-2/3 w-4/5  tracking-normal">
          Transition to CloudOps, the evolution of DevOps. With the support of an AWS Advanced Partner, select the right
          cloud solution, forecast expenditures, and empower your team to construct intelligent workloads.
        </p>
        <Button classNames="bg-primary text-white text-base">Book free consultation</Button>
      </div>
      <div className="flex flex-col gap-20 place-content-center items-center">
        {optimizeDAta.map((item, index) => {
          return (
            <div
              key={item.id}
              className={`grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4  ${
                index % 2 === 0 ? 'flex-row-reverse' : ''
              }`}
            >
              <div className={`relative flex flex-col place-content-center lg:ml-20 `}>
                <span
                  className={`hidden md:block absolute opacity-5 text-[200px] font-extrabold  ${
                    index % 2 === 0 ? '-top-24 -left-16' : '-top-24 -left-8'
                  }`}
                >
                  {item.num}
                </span>
                <div className="flex gap-3">
                  <img src="/assets/icons/configuration.png" alt="" />
                  <p className="font-bold text-2xl md:text-4xl md:tracking-wide leading-12">{item.title}</p>
                </div>
                <p className="text-secondary text-xl md:text-3xl leading-6 md:leading-8  font-semibold lg:mr-44">
                  {item.description}
                </p>
              </div>
              <div className={`${index % 2 === 0 ? '' : 'md:order-first '}`}>
                <img src={item.icon} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnerOptimize;
