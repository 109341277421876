import React from 'react';
import {
  AWSMainSection,
  CloudMastery,
  EffortlessAWSEvolution,
  FreeSubscribe,
  PartnerOptimize,
} from 'modules/aws-development/components/organisims';
import { ScrollToTop } from 'modules/shared/components/molecules';
import { SeoMetaTags } from 'modules/shared/components/organisms';

const AwsDevelopment = () => {
  return (
    <div>
      <SeoMetaTags
        title="Indegree | AWS Development"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="bg-gray-100 h-16"></div>
      <div className="mt-2 flex flex-col gap-16">
        <AWSMainSection />
        <CloudMastery />
        <PartnerOptimize />
        <EffortlessAWSEvolution />
        <FreeSubscribe />
      </div>
      <ScrollToTop />
    </div>
  );
};

export { AwsDevelopment };
