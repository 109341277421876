import React from 'react';
import { ServiceCatalog, BasicCard, WhyIndegree } from 'modules/home/components/molecules';
import { TecnologiesList, SubscribeCard, SeoMetaTags } from 'modules/shared/components/organisms';
import { Button, Typography } from 'modules/shared/components/atoms';
import { CloudRoi, WhereAreWE, BestSolutions } from 'modules/home/components/organisms';
import { MpoweringBusinesses } from 'modules/shared/components/organisms';
import './styles.css';
import { ScrollToTop } from 'modules/shared/components/molecules';
const exampleData = [
  {
    id: 1,
    icon: '/assets/icons/rocket-icon.svg',
    title: 'Powerful Performance',
    description:
      'Experience unmatched performance with our software. From managing large datasets to executing complex tasks, our solution ensures swift and seamless operation, empowering users to achieve more in less time',
  },
  {
    id: 2,
    icon: '/assets/icons/shield-icon.svg',
    title: 'Robust Security Measures',
    description:
      "Trust your data's security with our fortified software. We prioritize protection through advanced encryption, authentication protocols, and proactive threat monitoring, giving you peace of mind in today's digital landscape",
  },
  {
    id: 3,
    icon: '/assets/icons/building-icon.svg',
    title: 'Scalable Architecture',
    description:
      'Our software features a scalable architecture that grows with your business needs. Whether startup or enterprise, it effortlessly adapts to increasing workloads, ensuring seamless scalability without compromising performance or stability.',
  },
];

const exampleData1 = [
  {
    id: 1,
    icon: '/assets/icons/KEYPOINTS 2.png',
    title: 'Vetted AWS Consulting Partner',
    description:
      'Experience unmatched performance with our software. From managing large datasets to executing complex tasks, our solution ensures swift and seamless operation, empowering users to achieve more in less time',
  },
  {
    id: 2,
    icon: '/assets/icons/KEYPOINTS 6.png',
    title: 'Track Record of Success',
    description:
      "Trust your data's security with our fortified software. We prioritize protection through advanced encryption, authentication protocols, and proactive threat monitoring, giving you peace of mind in today's digital landscape",
  },
  {
    id: 3,
    icon: '/assets/icons/KEYPOINTS 7.png',
    title: 'Reduce risk by adopting Successful Methodologies',
  },
  {
    id: 4,
    icon: 'assets/icons/KEYPOINTS 7.png',
    title: 'Unlock the Highest Values using our Cloud Managed Services',
  },
  {
    id: 5,
    icon: 'assets/icons/KEYPOINTS 7.png',
    title: 'Lower TCO by accessing AWS funded programs',
  },
];
const exampleData2 = [
  {
    id: 1,
    icon: '/assets/images/Image-app-development.png',
    title: 'Apps and Web Development',
    link: '/services/web-mobile-development',
    description: 'Build a digital product with top-tier performance, ensuring seamless scalability for rapid growth.',
  },
  {
    id: 2,
    icon: '/assets/images/server.png',
    title: 'Cloud & DevOps',
    description:
      'Minimize deployment time, optimize your cloud expenses, and prime your product for seamless scalability.....',
  },
  {
    id: 3,
    icon: '/assets/images/syncing.png',
    title: 'App Modernization',
    description:
      'Evaluate performance, cloud utilization, and usability -modernize legacy code or transition to advanced technologies.',
    link: '/services/modernization',
  },
  {
    id: 4,
    icon: '/assets/images/Image-data-science.png',
    title: 'DATA ENGINEERING',
    description: 'Transform your operations with the data you already have',
    link: '/services/data-engineering',
  },
  {
    id: 5,
    icon: '/assets/images/Image-artificial-intelligence.png',
    title: 'Artificial Intelligence(AI) and MachineLearning (ML)',
    description: 'we harness the power of Artificial Intelligence (AI) and Machine Learning (ML) to.....',
    link: '/services/artificial-intelligence',
  },
  {
    id: 6,
    icon: '/assets/images/aws-icon.png',
    title: 'AWS Development',
    description: 'Choose the best AWS services and master cloud development with an AWS Advanced Partner.',
    link: '/services/aws-development',
  },
  {
    id: 7,
    icon: '/assets/images/saas.png',
    title: 'SAAS Products Development',
    description:
      'Choosing a Software as a Service (SaaS) model for software delivery offers several advantages that make......',
    link: '/services/sass-development',
  },
];
const HomePage = () => {
  return (
    <div>
      <SeoMetaTags
        title="Indegree | Home"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="font-Darker_Grotesque">
        <div className="h-28"></div>
        <div className="banner_section">
          <div className="container">
            <div className="grid  md:grid-cols-2 grid-cols-1 lg:py-4">
              <div className="lg:col-span-1 md:col-span-2 grid grid-cols-2 place-items-center  lg:flex flex-col gap-10 lg:gap-12 mt-2">
                <div className="relative text-center lg:text-start col-span-2 md:col-span-1">
                  <span className="lg:text-5xl text-2xl pl-1 font-bold tracking-wide lg:leading-12">
                    A Comprehensive Development Partner For All Your Needs
                  </span>
                  <p className="tracking-wide mt-4 lg:leading-8 leading-6 text-xl">
                    Experience excellence in custom software development with our exceptional team.
                    <span className="hidden lg:block">
                      Collaborate with top-tier designers, front-end and back-end developers, cloud architects, and
                      DevOps experts. With over 100 successful projects under our belt, trust our years of expertise for
                      full-cycle custom software solutions.
                    </span>
                  </p>
                  <div className="md:hidden">
                    <p className="absolute -top-2 left-3 w-4 h-4 bg-sky-700 opacity-50 rounded-full"></p>
                    <p className="absolute top-2 right-5 w-4 h-4 bg-secondary opacity-40 rounded-full"></p>
                    <p className="absolute -bottom-8 right-2 w-3.5 h-3.5 bg-primary opacity-50 rounded-full"></p>
                    <p className="absolute bottom-9 left-6 w-2 h-2 bg-yellow-400 opacity-50 rounded-full"></p>
                  </div>
                  <div className="flex justify-center mt-4 lg:justify-start">
                    <Button classNames="bg-primary text-white w-fit ">Book free consultation</Button>
                  </div>
                </div>
                <div className="lg:hidden col-span-2 md:col-span-1">
                  <img src="/assets/images/Home_page_phone.png" alt="" />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap  justify-center ">
              {exampleData.map((item) => (
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 pt-4 lg:mt-20">
                  <BasicCard
                    key={item.id}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    classNames={''}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container hidden lg:block">
          <div className="flex flex-col gap-12 py-10">
            <p className=" place-content-center text-4xl tracking-wide font-bold flex">
              Our Distinction <span className="text-primary ml-2">Key points</span>
            </p>
            <div className="grid grid-cols-2 md:grid-cols-3 md:gap-2 lg:grid-cols-5 gap-4">
              {exampleData1.map((item) => (
                <div className="items-center text-center flex flex-col gap-4">
                  <div className="group">
                    <div className="relative">
                      <img width="217px" height="190px" src={item.icon} alt="icon" />
                      <div className="cursor-pointer absolute top-0 left-0 bottom-0 r-0">
                        <img
                          className="group-hover:filter-gray-to-primary group-hover:-rotate-45 duration-500 transition-transform"
                          src="/assets/icons/circle_gray.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-lg font-semibold ">{item.title}</p>
                </div>
              ))}
            </div>
            <div className="m-auto">
              <Button variant="ghost" icon={'assets/icons/arrow-narrow-right.svg'}>
                Free Consulting
              </Button>
            </div>
          </div>
        </div>
        <div id="services" className="container py-12">
          <p className="text-2xl lg:text-4xl font-bold text-center tracking-wide my-6">
            What would you like to <span className="text-primary">get done?</span>
          </p>
          <div className="grid grid-col-1 md:grid-cols-2 md:gap-2 lg:grid-cols-3 gap-6">
            {exampleData2.map((item, index) => (
              <div
                key={index}
                className={
                  index === exampleData2.length - 1
                    ? 'lg:col-start-2 lg:self-center md:col-span-2 md:w-1/2 md:mx-auto lg:mx-0  place-items-center'
                    : 'grid '
                }
              >
                <ServiceCatalog
                  key={item.id}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  link={item.link}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="container">
          <div className="my-16 relative">
            <MpoweringBusinesses />

            <div className="hidden lg:flex absolute  items-center  left-[-62px] top-0 bottom-0 z-[-1]">
              <img className="" src="/assets/images/output-onlinepngtools.png" alt="" />
            </div>

            <div className="hidden lg:flex absolute items-center right-[-62px] top-0 bottom-0 z-[-1]">
              <img className="" src="/assets/images/output-onlinepngtools.png" alt="" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="flex flex-col my-6 items-center">
            <h2 className="lg:text-5xl text-2xl font-bold text-center tracking-wide w-fit">Our Partners</h2>
            <span className="text-center lg:w-44 w-36 mt-1 md:border-b-4 border-b-2 lg:mt-2 border-primary"></span>
          </div>
          <div className="flex md:justify-around justify-between  content-center items-center mx-4">
            <div className="lg:w-36 w-20 mt-5">
              <img src="/assets/images/aws.png" alt="" />
            </div>
            <div className="lg:h-14 h-6 w-40 lg:w-64 mr-5">
              <img src="/assets/images/microsoft.png" alt="" />
            </div>
          </div>
        </div>
        <div className="mx-8">
          <TecnologiesList />
        </div>
        <div className="container my-20 ">
          <SubscribeCard title='Collaborate with teams that CTOs commend for being "friendly" and "reliable".'>
            <Button classNames="text-white boeder border-white" icon="/assets/icons/arrow-narrow-right-white.svg">
              Book free consultation
            </Button>
          </SubscribeCard>
        </div>
        <div className="container">
          <CloudRoi />
          <WhyIndegree />
        </div>
        <div className="">
          <BestSolutions />
        </div>
        <div className="container md:my-28 my-10">
          <SubscribeCard
            title="Subscribe to our newsletter and join the innovation journey"
            description="Stay in touch with our team here at In Degree Stay ahead of the curve with our software updates, industry insights, and special offers"
          >
            <div className="">
              <form className="flex bg-white rounded-full p-1 ">
                <input
                  type="text"
                  className="rounded-full md:w-80 w-40 pl-2 outline-none"
                  placeholder="Write Your Mail"
                />
                <Button classNames="bg-primary text-white px-8">Subscribe</Button>
              </form>
            </div>
          </SubscribeCard>
        </div>
        <div className="container">
          <div className="">
            <Typography variant="section" className="text-center text-3xl">
              Where Are We
            </Typography>
          </div>
        </div>
        <WhereAreWE />
        <ScrollToTop />
      </div>
    </div>
  );
};

export default HomePage;
