import React from 'react';
import { Typography } from 'modules/shared/components/atoms';

const OnlinePresenceBooster = () => {
  return (
    <div>
      <div className="text-center hidden md:block">
        <Typography htmlTag="span" variant="section" className="">
          Elevate Your Online Presence with
        </Typography>
        <Typography htmlTag="span" variant="section" className="text-primary">
          Our Web Development Expertise.
        </Typography>
      </div>
      <article className="grid grid-cols gap-6 lg:grid-cols-2 my-8 ">
        <section>
          <div className="text-center lg:text-start">
            <Typography
              htmlTag="span"
              variant="title"
              className="font-semibold text-2xl lg:text-3xl lg:pr-16 pr-4 leading-8"
            >
              Comprehensive Web Development Solutions from Concept to Completion
            </Typography>
          </div>
          <div>
            <article className="my-4 text-lg lg:text-2xl leading-6 lg:leading-7 text-center lg:text-start">
              <span className="font-semibold ">Defining Reliability in Outsourcing:</span>
              <span className="text-secondary font-medium">
                Reliability transcends the mere execution of tasks; it embodies the seamless integration of an
                outsourced team with your internal operations, mirroring the efficiency and dedication of your in-house
                web developers. Our web development services ,endorsed by 98% of Chief Technology Officers (CTOs),
                epitomize this principle .Our team is not only proficient in developing APIs, custom web applications ,
                and hybrid mobile applications but also specializes in web design, technology stack updates, and the
                construction of cloud-native systems, ensuring a holistic and understanding partnership.
              </span>
            </article>
            <article>
              <article className="my-4 text-lg lg:text-2xl leading-6 lg:leading-7 text-center lg:text-start">
                <span className="font-semibold">Your Single Source for Complete Web Development Expertise:</span>
                <span className="text-secondary font-medium">
                  Our team is a one-stop solution for your project needs ,comprising Business Analysts, Product
                  Designers, Project Managers, Quality Assurance (QA) Engineers, DevOps specialists, and Full-stack
                  Developers. Wep ride ourselves on a proactive approach, offering a wealth of improvement suggestions
                  to drive your business forward, including enhancements in search engine optimization (SEO).
                </span>
              </article>
            </article>
          </div>
        </section>
        <div className="self-center">
          <img className=" rounded-lg" src="/assets/images/brain_storming.png" alt="" />
        </div>
      </article>
    </div>
  );
};

export default OnlinePresenceBooster;
