import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const DataTransformationModule = ({ iconUrl, title }) => {
  return (
    <div className="shadow-xl p-8 rounded-xl bg-white">
      <div className="flex gap-6 md:gap-6 flex-col justify-center items-center">
        <img width="88px" height="88px" className="m-auto" src={iconUrl} alt="" />
        <Typography variant="title" className="font-semibold text-3xl text-center leading-8">
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default DataTransformationModule;
