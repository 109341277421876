import { Typography } from 'modules/shared/components/atoms';
import React from 'react';

const AIMLServicesCard = ({ num, title, description }) => {
  return (
    <div className="bg-white p-4 rounded-3xl shadow-xl shadow-rose-100 border border-stroke font-darker-grotesque">
      <div className="flex gap-2 items-center mb-4">
        <span className="text-primary rounded-xl px-2 border-2 border-primary text-lg font-bold">{num}</span>
        <Typography variant="overview" htmlTag="h3" className="text-xl font-bold">
          {title}
        </Typography>
      </div>
      <p className="text-sm md:text-base">{description}</p>
    </div>
  );
};

export default AIMLServicesCard;
