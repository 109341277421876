import React from 'react';
import { SaasDevelopmentCard, SaasProductsType, WhySaas } from '../components/organisms';
import { ScrollToTop } from 'modules/shared/components/molecules';
import { SeoMetaTags } from 'modules/shared/components/organisms';

const SassDevelopment = () => {
  return (
    <div>
      <SeoMetaTags
        title="Indegree | SaaS Development"
        description="Experience excellence in custom software development with our exceptional team."
        image="/assets/images/why-indegree1.png"
        keywords="Indegree, Home, IT solutions, Software development, Cloud computing, App development, AI development"
      />
      <div className="h-28"></div>
      <div className="flex flex-col gap-20">
        <SaasDevelopmentCard />
        <SaasProductsType />
        <WhySaas />
      </div>
      <ScrollToTop />
    </div>
  );
};

export { SassDevelopment };
