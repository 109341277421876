import React from 'react';
import { Button } from 'modules/shared/components/atoms';
import './style.css';
const DataEngineering = () => {
  return (
    <div>
      <div className="h-16"></div>
      <div className="banner-wrapper mt-3 font-darker-grotesque">
        <div className="flex flex-col md:gap-10 gap-4 place-content-center items-center text-center md:pt-20  py-14">
          <p className="text-white text-3xl lg:text-5xl font-bold">Data Engineering</p>
          <p className="text-white text-lg lg:w-1/2 md:w-7/12 px-6 lg:px-12 leading-6 md:leading-8 tracking-wide">
            Welcome to In Degree, where we specialize in unleashing the power of data through cutting-edge technologies
            and unparalleled expertise. Introducing our comprehensive Data Engineering service, designed to propel your
            organization into a realm of data-driven decision-making and innovation.
          </p>
          <Button classNames="bg-primary text-white ">Book free consultation</Button>
        </div>
      </div>
    </div>
  );
};

export default DataEngineering;
