// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../public/assets/images/map.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../../public/assets/images/map_phone.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhereAreWE {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px;
  width: 100%;
}
@media (-width: 768px) {
  .WhereAreWE {
    height: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .WhereAreWE {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/home/components/organisms/WhereAreWE/style.css"],"names":[],"mappings":"AAAA;EACE,yDAAuE;EACvE,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,aAAa;EACb,WAAW;AACb;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,yDAA6E;EAC/E;AACF","sourcesContent":[".WhereAreWE {\n  background-image: url('../../../../../../public/assets/images/map.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  height: 600px;\n  width: 100%;\n}\n@media (-width: 768px) {\n  .WhereAreWE {\n    height: 400px;\n  }\n}\n@media only screen and (max-width: 768px) {\n  .WhereAreWE {\n    background-image: url('../../../../../../public/assets/images/map_phone.png');\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
