import React from 'react';

const CloudRoi = () => {
  const images = [
    'assets/icons/aws1.png',
    'assets/icons/aws2.png',
    'assets/icons/aws3.png',
    'assets/icons/aws4.png',
    'assets/icons/aws5.png',
    'assets/icons/aws6.png',
    'assets/icons/aws7.png',
    'assets/icons/aws8.png',
  ];

  return (
    <div className="font-darker-grotesque rounded-xl bg-gray-100 md:py-8 py-3 lg:my-16 md:my-10 my-4 ">
      <div className="lg:w-9/12 w-11/12 mx-auto grid gap-8 ">
        <div className="flex items-center justify-center p-4">
          <img src="/assets/icons/aws-partner.png" alt="" />
        </div>
        <div className="flex flex-col items-center text-center gap-4">
          <span className="font-bold lg:text-4xl md:text-2xl text-xl mb-2">
            Enhance your cloud's
            <span className="text-primary font-darker-grotesque"> ROI</span>
          </span>
          <p className="text-secondary text-sm md:text-base">
            keeping pace with market competitors who have already perfected Cloud Ops under the mentorship of an
            official AWS Advanced Partner. Reduce expenses, implement observability, or select the most suitable service
            sto optimize your cloud strategy.
          </p>
        </div>

        <div className="flex justify-center gap-5 lg:gap-6 flex-wrap">
          {images.map((image, index) => (
            <div className="w-20 lg:w-24">
              <img src={image} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CloudRoi;
