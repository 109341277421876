import React from 'react';
import { Button } from 'modules/shared/components/atoms';
import { Typography } from 'modules/shared/components/atoms';
const WebAppDevelopmentCard = () => {
  return (
    <div className="flex flex-col gap-6 w-full md:w-10/12 m-auto rounded-md bg-gray-100 py-8 lg:p-10">
      <div className="text-center">
        <div className="text-2xl leading-9 lg:leading-12 lg:text-5xl  font-bold">
          <p className="text-cente">Work with a development team</p>
          Highly praised by <span className="text-center text-primary">CTOs for their exceptional skills.</span>
        </div>
      </div>
      <Typography
        htmlTag="p"
        variant="title"
        className="text-center font-medium lg:mx-20 mx-4 text-secondary leading-7"
      >
        No matter the web applications you're building, we're here to support you at every stage of Apps and Web
        development . Our team of the designers and developers has built and served over 80 companies globally — all
        aligned with each client's digital marketing strategy.
      </Typography>
      <div className="text-center">
        <Button
          icon={'/assets/icons/arrow-narrow-right-white.svg'}
          classNames="bg-primary text-white w-fit mx-auto tracking-wide py-2.5 px-8"
        >
          Get Service Now
        </Button>
      </div>
    </div>
  );
};

export default WebAppDevelopmentCard;
