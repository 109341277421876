import clsx from 'clsx';
import React from 'react';

const Button = ({ icon, classNames, children, onClick, type = 'button', variant = 'primary' }) => {
  const buttonVarantsStyles = {
    primary:
      'bg-primary hover:text-primary hover:bg-white text-white border border-primary outline-none rounded-3xl transition-all duration-300 ease-linear',
    secondary:
      'bg-white hover:text-white hover:bg-primary text-primary border border-white outline-none rounded-3xl transition-all duration-300 ease-linear',
    ghost:
      'text-primary border-primary hover:text-white hover:bg-primary border outline-none rounded-3xl transition-all duration-300 ease-linear',
    text: 'text-primary',
  };

  const imageVariantStyles = {
    primary: 'group-hover:filter-to-primary',
    ghost: 'group-hover:filter-to-white',
    secondary: 'group-hover:filter-to-white',
  };
  const baseStyles =
    'group flex justify-center items-center gap-2 cursor-pointer font-semibold text-xl px-6 py-2 leading-6';
  const butonStyles = buttonVarantsStyles[variant];
  const iconStyles = imageVariantStyles[variant];
  return (
    <button type={type} className={clsx(baseStyles, butonStyles, classNames)} onClick={onClick}>
      {children}
      {icon && <img src={icon} alt="icon" className={`${iconStyles}`} />}
    </button>
  );
};

export default Button;
