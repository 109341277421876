import React from 'react';
import { Typography } from '../../atoms';
import { Link } from 'react-router-dom';

const socialIcons = [
  {
    id: 1,
    url: 'assets/icons/linkedin-icon.png',
    title: 'LinkedIn',
  },
  {
    id: 2,
    url: 'assets/icons/behance-icon.png',
    title: 'Twitter',
  },
  {
    id: 3,
    url: 'assets/icons/facebook-icon.png',
    title: 'Facebook',
  },
  {
    id: 4,
    url: 'assets/icons/instagram-icon.png',
    title: 'Instagram',
  },
];
const servicesLinks = [
  {
    id: 1,
    title: 'Apps and Web Development',
    url: '/services/web-mobile-development',
  },
  {
    id: 2,
    title: 'Cloud & DevOps',
    url: '#',
  },
  {
    id: 3,
    title: 'App Modernization',
    url: '/services/modernization',
  },
  {
    id: 4,
    title: '(AI) and (ML)',
    url: '/services/artificial-intelligence',
  },
  {
    id: 5,
    title: 'AWS Development',
    url: '/services/aws-development',
  },
  {
    id: 6,
    title: 'DATA ENGINEERING',
    url: '/services/data-engineering',
  },
  {
    id: 7,
    title: 'SAAS Products Development',
    url: '/services/sass-development',
  },
];
const mainLinks = [
  {
    id: 1,
    title: 'Company',
  },
  {
    id: 2,
    title: 'Services',
  },
  {
    id: 3,
    title: 'Technologies',
  },
  {
    id: 4,
    title: 'Industries',
  },
  {
    id: 5,
    title: 'Careers',
  },
];
const contactDetails = [
  {
    id: 1,
    address: 'Qatar , Doha ',
    phone: '+974  777  28  745 ',
  },
  {
    id: 2,
    address: 'Saudi Arabia , Jeddah  ',
    phone: '+966  51  156  3965',
  },
  {
    id: 3,
    address: 'Egypt , Cairo ',
    phone: '+20  107  070  3355',
  },
];
const Footer = () => {
  return (
    <div id="footer" className="bg-gray-100 relative md:mt-20 mt-8 font-darker-grotesque">
      <div className="container md:py-10 py-4">
        <div className="grid lg:grid-cols-5 gap-4 md:grid-cols-3 grid-cols-1">
          <div className="col-span-2 lg:pr-24 grid gap-4 md:mb-3 ">
            <Link to="/">
              <img src="/assets/main_logo/indegree_logo.png" alt="" />
            </Link>
            <p className="md:tracking-wide leading-7 text-xl font-medium">
              In Degree Digital Services, established in 2020, emerged from the collaboration of a group of seasoned
              software developers and adept business executives with a notable presence in Qatar, Egypt, and KSA.
            </p>
            <div className="flex gap-5 justify-center lg:justify-start">
              {socialIcons.map((item, index) => (
                <div key={item.id}>
                  <img src={item.url} alt="" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-3 grid md:grid-cols-3 grid-cols-1 gap-3">
            <div className="col-span-1">
              <p className="text-xl font-semibold flex flex-col mb-2">
                Services
                <span className="border-b border-y-2 border-primary w-14"></span>
              </p>
              {servicesLinks.map((item, index) => (
                <div key={item.id} className="py-1 md:py-2 text-base">
                  <Link to={item?.url}>{item.title}</Link>
                </div>
              ))}
            </div>
            <div className="col-span-1">
              <p className="text-xl font-semibold flex flex-col mb-2 ">
                Main Links
                <span className="border-b border-y-2 border-primary w-14"></span>
              </p>
              {mainLinks.map((item, index) => (
                <div key={item.id} className="py-1 md:py-2 text-base">
                  <Link to="/">{item.title}</Link>
                </div>
              ))}
            </div>
            <div className="col-span-1 md:col-span-1">
              <p className="text-xl font-semibold flex flex-col mb-2">
                Contact
                <span className="border-b border-y-2 border-primary w-14"></span>
              </p>
              {contactDetails.map((item, index) => (
                <div>
                  <div key={item.id} className={`grid gap-2 py-1 md:py-2 text-xl md:gap-4 mb-2`}>
                    <div className="flex gap-2  ">
                      <img src="/assets/icons/location2.png" alt="" />
                      <p>{item.address}</p>
                    </div>
                    <div className="flex gap-2">
                      <img src="/assets/icons/phone.png" alt="" />
                      <p>{item.phone}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
