import React from 'react';
import { Button } from 'modules/shared/components/atoms';
import { Typography } from 'modules/shared/components/atoms';
const WebAppDevelopmentCard = () => {
  return (
    <div className="flex flex-col gap-4 lg:gap-8 w-full md:w-1/2 m-auto">
      <Typography htmlTag="h2" variant="section" className="text-center">
        Apps and Web Development
      </Typography>
      <Typography htmlTag="p" variant="description" className="text-center font-medium lg:w-3/4 mx-auto">
        Build a digital product with top-tier performance, ensuring seamless scalability for rapid growth . We
        specialize in crafting cutting-edge applications and websites tailored to meet your unique needs. Whether you're
        envisioning a sleek mobile app or a robust web platform, our team of skilled developers, designers, and
        architects is here to bring your ideas to life.
      </Typography>
      <div className="flex justify-center ">
        <Button classNames="bg-primary text-white text-base">Book free consultation</Button>
      </div>
    </div>
  );
};

export default WebAppDevelopmentCard;
